.uploadBox {
  min-height: 300px;
  width: 100%;
}

.uploadIcon {
  animation: MoveUpAndDown 1s infinite alternate;
}

@keyframes MoveUpAndDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
