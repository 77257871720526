:root {
  /* Typography */
  --amplify-font-family: 'Rubik', sans-serif;

  --amplify-text-xxs: 0.75rem;
  --amplify-text-xs: 0.81rem;
  --amplify-text-sm: 0.875rem;
  --amplify-text-md: 1rem;
  --amplify-text-md-sub: 1.15rem;
  --amplify-text-lg: 1.5rem;
  --amplify-text-xl: 2rem;
  --amplify-text-xxl: 2.5rem;

  /* Colors */
  --amplify-primary-color: #ea0053;
  --amplify-primary-contrast: var(--amplify-white);
  --amplify-primary-tint: #ea0053;
  --amplify-primary-shade: #ea0053;

  --amplify-secondary-color: #1f2644;
  --amplify-secondary-contrast: var(--amplify-white);
  --amplify-secondary-tint: #1f2644;
  --amplify-secondary-shade: #1f2644;

  --amplify-tertiary-color: #5d8aff;
  --amplify-tertiary-contrast: var(--amplify-white);
  --amplify-tertiary-tint: #7da1ff;
  --amplify-tertiary-shade: #537be5;

  --amplify-background-color: var(--amplify-white);

  /* Neutral */
  --amplify-grey: #828282;
  --amplify-light-grey: #c4c4c4;
  --amplify-white: #ffffff;
  --amplify-smoke-white: #f5f5f5;
  --amplify-red: #dd3f5b;
  --amplify-blue: #099ac8;
}

button {
  --border-radius: 0 px 8 px 8 px;
}
